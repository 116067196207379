<template>
  <div class="login-page">
    <div class="login-container">
      <el-form
        label-position="left"
        label-width="0px"
        class="login-form"
        v-loading="login_actions.disabled"
        :element-loading-text="generateColumns('logining')"
        :model="data"
        :rules="rule_data"
        ref="data"
      >
        <h2 v-if="loginType === 'teacher'">
          {{ generateColumns("papa_live_teacher") }}
        </h2>
        <h2 v-else>{{ generateColumns("papa_live_help_teacher") }}</h2>

        <el-form-item prop="username">
          <el-input
            type="text"
            auto-complete="off"
            placeholder="用户名"
            :autofocus="true"
            style="width: 400px !important; max-width: 400px"
            v-model="data.username"
            @keyup.native.enter="onLogin('data')"
          />
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            type="password"
            auto-complete="off"
            placeholder="密码"
            style="width: 400px !important; max-width: 400px"
            v-model="data.password"
            @keyup.native.enter="onLogin('data')"
          />
        </el-form-item>

        <el-form-item>
          <el-button @click="onLogin('data')">登录</el-button>
        </el-form-item>
        
        <!--        <div class="change-type" v-if="loginType === 'assistant' " @click="changeUserType('teacher')">切换教师端</div>-->
        <!--        <div class="change-type" v-else @click="changeUserType('assistant')">切换助教端</div>-->
      </el-form>
    </div>

    <div class="user-type teacher"></div>
    <div class="user-type assistant"></div>
    <div id="captcha"></div>
  </div>
</template>

<script>
import LoginJs from "./login.js";

export default LoginJs;
</script>

<style lang="less" scoped>
.login-page {
  & > .login-container {
    padding-top: 10%;
    padding-left: calc(~"50% - 200px");
    background: #ffffff;
    height: 100vh;
    box-sizing: border-box;

    .login-form {
      border-radius: 4px;
      padding: 30px;
      -webkit-box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.1);
      -o-box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.1);
      box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.04);
      background: #ffffff;
      width: 400px;

      h2 {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 20px !important;
        margin-bottom: 30px;
        color: #000;
      }

      .el-form-item {
        margin-bottom: 30px;
      }

      .el-input__inner {
        font-size: 16px;
        font-weight: 300;
        height: 50px;
        padding-left: 0;
        padding-right: 0;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }

    .el-input__inner {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 12px;
      line-height: 1.42857143;
      color: #555;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    .el-button {
      width: 100%;
      height: 44px;
      padding-right: 50px;
      padding-left: 50px;
      border: none;
      background: #29d087;
      color: #ffffff;
    }
  }

  .change-type {
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #666;
  }
}
</style>
