import { login, getIMToken, getProfile, tencentConfigApi } from '@/api/login/login.js'
import { mapActions, mapState } from 'vuex'
import Layout from '@/layout/layout'
import router from '@/router/index'
import { generateColumns, generateTips } from '@/utils/i18n'

export default {
  name: 'login', data () {
    return {
      routes: [{
        path: '/', name: 'main', redirect: '/login'
      }, {
        path: '/login', name: 'login', component: () => import('@/views/login/login.vue'), hidden: true
      }, {
        path: '/dashboard', name: 'index', hidden: true, component: () => import('@/layout/layout.vue'), redirect: '/dashboard/index', children: [{
          path: 'index', meta: { title: 'dashboard', icon: 'courseCenter' }, name: 'dashboardIndex', component: () => import('@/views/dashboard/index.vue')
        }]
      }], login_actions: {
        disabled: false
      }, loginType: 'teacher', data: {
        username: '', password: ''
      }, rule_data: {
        username: [{
          validator: ( rule, value, callback ) => {
            if (value === '') {
              callback(new Error(this.generateColumns('input_username')))
            } else {
              callback()
            }
          }, trigger: 'blur'
        }], password: [{
          validator: ( rule, value, callback ) => {
            if (value === '') {
              callback(new Error(this.generateColumns('input_password')))
            } else {
              if (!/^.{4,16}$/.test(value)) {
                callback(new Error(this.generateColumns('password_format_error')))
              } else {
                callback()
              }
            }
          }, trigger: 'blur'
        }]
      }
    }
  }, computed: {
    ...mapState({
      authorization: state => state.user.authorization
    })
  }, methods: {
    generateColumns, generateTips, onLogin ( ref ) {
      this.$refs[ ref ].validate(valid => {
        if (valid) {
          this.login_actions.disabled = true
          tencentConfigApi({}, res => {
            const callback = ( res ) => {
              if (res.ret === 0 && !res.errorCode) {
                login({
                  ...this.data, ticket: res.ticket, rand_str: res.randstr,
                }, data => {
                  this.setAuthorization(data)
                  localStorage.authorization = JSON.stringify(data)
                  this.setUserIMId(data.uuid)
                  setTimeout(() => {
                    getProfile(response => {
                      this.setUserInfo(response)
                      localStorage.userInfo = JSON.stringify(response)
                      this.addRoute(response.roles)
                      if (sessionStorage.targetUrl) {
                        window.location.href = sessionStorage.targetUrl
                      } else {
                        this.$router.replace({
                          name: 'index'
                        })
                      }
                    }, '', this.loginType)
                    getIMToken(res => {
                      this.setUserIMToken(res.user_sign)
                      this.$cookies.set('userSign', res.user_sign, '20d')
                      sessionStorage.userSign = this.$cookies.get('userSign')
                    }, '', this.loginType)
                  }, 2000)
                }, () => {
                  this.login_actions.disabled = false
                }, this.loginType)
              } else {
                this.login_actions.disabled = false
              }
            }
            new window.TencentCaptcha('196548770', callback, {
              aidEncrypted: res.encrypt_key
            }).show()
            tencentCaptcha.show()

          }, err => {

          })

        }
      })
    }, changeUserType ( type ) {
      this.loginType = type
      localStorage.loginType = type
    }, addRoute ( type ) {
      this.$router.options.routes = this.routes
      const personalCourse = [{
        path: '/course', name: 'course', component: Layout, redirect: '/course/schedule', meta: {
          title: 'courseCenter', icon: 'course1', role: 'personal-course'
        }, children: [{
          path: 'schedule', component: () => import('@/views/course/schedule/schedule.vue'), meta: { title: 'mySchedule', icon: '' }
        }, {
          path: 'monthSchedule', component: () => import('@/views/course/monthSchedule/monthSchedule.vue'), meta: { title: 'monthSchedule', icon: '' }
        }, {
          path: 'teacherArrange', component: () => import(
              '@/views/course/teacherMonthSchedule/teacherArrange.vue'
              ), meta: { title: 'teacherArrange', icon: '' }
        }, {
          path: 'myCourse', component: () => import('@/views/course/myCourse/myCourse.vue'), meta: { title: 'myCourse', icon: '' }, children: [{
            path: 'detail/:id', components: {
              default: import('@/views/course/myCourse/myCourse.vue'), detail: () => import(
                  '@/views/course/myCourse/courseDetail/courseDetail.vue'
                  )
            }, // component:() => import('@/views/course/myCourse/courseDetail/courseDetail.vue'),
            meta: { title: 'detail' }, hidden: true
          }, {
            path: 'private/detail/:id', components: {
              default: import('@/views/course/myCourse/myCourse.vue'), detail: () => import(
                  '@/views/course/myCourse/courseDetail/courseDetail.vue'
                  )
            }, // component:() => import('@/views/course/myCourse/courseDetail/courseDetail.vue'),
            meta: { title: 'detail' }, hidden: true
          }]
        }]
      }, {
        path: '/private/live/:id', name: 'privateLive', component: () => import('@/views/live/live.vue'), hidden: true
      }, {
        path: '/live/:id', name: 'live', component: () => import('@/views/live/live.vue'), hidden: true
      }, {
        path: '/course/private/replay/:id', component: () => import('@/views/course/courseReplay/courseReplay.vue'), hidden: true
      }, {
        path: '/course/replay/:id', component: () => import('@/views/course/courseReplay/courseReplay.vue'), hidden: true
      }]
      const trainingSpeaking = [{
        path: '/trainingSpeaking', name: 'training', component: Layout, redirect: '/trainingspeaking_appointments', meta: {
          title: 'trainingSpeaking', icon: 'kouyu_lianxinor', role: 'training-speaking'
        }, children: [{
          path: 'schedules', meta: { title: 'schedule', icon: '' }, name: 'trainingSchedules', component: () => import('@/views/trainingSpeaking/schedules/index.vue')
        }, {
          path: 'appointments', meta: { title: 'appointments', icon: '' }, name: 'appointments', component: () => import('@/views/trainingSpeaking/appointments/index.vue')
        }, // {
          //     path: 'plans',
          //     meta: {title: 'plans', icon: ''},
          //     name: 'trainingPlans',
          //     component: () => import('@/views/trainingSpeaking/plans/index.vue'),
          // },
          {
            path: 'analytic', meta: { title: 'analytic', icon: '' }, name: 'trainingAnalytic', component: () => import('@/views/trainingSpeaking/analytic/analytic.vue')
          }, {
            path: 'mark', meta: { title: 'mark', icon: '' }, name: 'mark', component: () => import('@/views/trainingSpeaking/mark/mark.vue')
          }]
      }]
      if (type.length === 0) {
        this.$router.options.routes = this.$router.options.routes.concat(personalCourse)
        router.addRoutes(personalCourse)
        console.log(this.$router.options)
      }
      if (type.indexOf('personal-course') > -1) {
        this.$router.options.routes = this.$router.options.routes.concat(personalCourse)
        router.addRoutes(personalCourse)
      }
      if (type.indexOf('training-speaking') > -1) {
        this.$router.options.routes = this.$router.options.routes.concat(trainingSpeaking)
        router.addRoutes(trainingSpeaking)
      }
    }, ...mapActions(['setAuthorization', 'setUserInfo', 'setUserIMId', 'setUserIMToken'])
  }, mounted () {
    this.loginType = localStorage.loginType || 'teacher'
    localStorage.loginType = this.loginType
    if (this.authorization && this.authorization.access_token) {
      this.$router.replace({
        name: 'main'
      })
    }
  }
}
